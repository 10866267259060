class localData {
  // Retrieve an item from localStorage and parse it to the correct type
  static get<T>(key: string): T | null {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue ? (JSON.parse(storedValue) as T) : null;
    } catch (error) {
      console.error(`Error parsing localStorage value for key "${key}":`, error);
      return null;
    }
  }

  // Store an item in localStorage after serializing it
  static set<T>(key: string, value: T): void {
    try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error setting localStorage value for key "${key}":`, error);
    }
  }

  // Remove an item from localStorage
  static remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing localStorage key "${key}":`, error);
    }
  }

  // Clear all items in localStorage
  static clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  }
}

export default localData;