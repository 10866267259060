import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get, iServerError } from '../Server';
import { appError} from './appErrorSlice';
import { RootState } from './store';

export type typeCongress = {
  id: number;
  bioguideid: string;
  district: string;
  elected_date: Date;
  courtesy: string;
  first_name: string;
  last_name: string;
  suffix: string;
  official_name: string;
  party: string;
  caucus: string;
  state: string;
  picture: string;
}

export type typeCongressAsset = {
  id: number;
  congress_id: number;
  congress_filing_id: number;
  security_id: number;
  asset: string;
  purchase_date: Date;
  notification_date: Date;
  asset_code: string;
  owner: string;
  action: string;
  shares: number;
  strike: number;
  min: number;
  max: number;
  expiration: Date;
  order_text: string;
  congress: typeCongress;
  congress_filing: typeCongressFiling;
}

export type typeCongressFiling = {
  id: number;
  congress_id: number;
  docid: string;
  filing_type: string;
  year: number;
  filing_date: Date;
  loaded_date: Date;
  url: string;
}

interface HashMap<T> {
  [key: number]: T
}
export interface iCongressState {
  error: string | null;
  isLoading: boolean;
  congresses: typeCongress[];
  congressAssets: typeCongressAsset[];
  congressAsset: HashMap<boolean>;
}
const initialState = {
  isLoading: false,
  error: null,
  congresses: [],
  congressAssets: [],
  congressAsset: {}
} satisfies iCongressState as iCongressState;

export const congressSlice = createSlice({
  name: 'congress',
  initialState,
  reducers: {
    congressError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCongress.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCongress.fulfilled, (state, action)  => {
        state.isLoading = false;
        state.error = null;
        state.congresses = (action.payload === null ? [] : action.payload as typeCongress[]) ;
      })
      .addCase(getCongress.rejected, (state)  => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getCongressAssets.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCongressAssets.fulfilled, (state, action)  => {
        const {congressID, assetArr} = action.payload;
        state.isLoading = false;
        state.error = null;
        if( assetArr.length > 0 ) {
          state.congressAsset[congressID] = true;
          state.congressAssets = assetArr;
        }
        // state.congressAssets = (action.payload === null ? [] : action.payload as typeCongressAsset[]) ;
      })
      .addCase(getCongressAssets.rejected, (state)  => {
        state.isLoading = false;
        state.error = null;
      })
  }
});

const getCongress = createAsyncThunk(
  "congress/getCongress",
  async (args,{dispatch, getState, rejectWithValue}) => {
    try {
      const congresses = await Get(`congresses.json`);
      return congresses;
    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);

const getCongressAssets = createAsyncThunk(
  "congress/getCongressAssets",
  async (congressID:number,{dispatch, getState, rejectWithValue}) => {
    try {
      const {congress} = getState() as RootState;
      const assets = await Get(`congress_assets/${congressID}.json`) as typeCongressAsset[];
      console.log("assets: ", assets)

      var assetArr: typeCongressAsset[] = [];
      if( assets === null ) {
        console.log("Return null....")
        return {congressID, assetArr}
      }

      assetArr = [...congress.congressAssets];
      //Only add trade ID's that don't exist (EG: closed)
      assets.map((data) => {
        const found = assetArr.find((ass)=>ass.id===data.id);
        if( !found ) assetArr.push( data );
        return data;
      })
      return {congressID,assetArr};

    } catch(err) {
      dispatch( appError(err as iServerError) );
      return rejectWithValue(err);
    }
  }
);


// const { congressError } = congressSlice.actions;
export default congressSlice.reducer;
export {getCongress, getCongressAssets};