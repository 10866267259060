import { useEffect, useState } from "react";
import { PlusCircle, XCircle } from "react-bootstrap-icons";
import { closeTrade, deleteTrade, tradeSources, tradeStatuses, tradeTypes, typeTrade, updateTrade } from "../platform/redux/tradeSlice";
import TradeAdd from "../components/TradeAdd";
import FormattedNumber from "../components/FormattedNumber";
import { useSelector } from "react-redux";
import { RootState } from "../platform/redux/store";
import { useAppDispatch } from "../hooks";
import { Link } from "react-router-dom";
import EditTextField from "./EditTextField";
import EditNumberField, { EditNumberFieldFormats } from "./EditNumberField";
import EditDateField from "./EditDateField";
import DropdownList from "./DropdownList";
import DropdownIDList from "./DropdownIDList";
import EditSearchField from "./EditSearchField";
import localData from "../platform/localData";

interface iTradeHeader {
  tradeTypeChange(val:string): void;
  tradeSourceChange(val:number): void;
  onTickerChange?: {(ticker:string): void};
  onTradeNumberChange?: {(tradeNumber:string): void}
  onTradeStatusChange?: {(status:string): void}
  detailPage?:boolean;
  trade?:typeTrade;
}

const TradeHeader = (props:iTradeHeader) => {
  const {tradeTypeChange, tradeSourceChange, detailPage, onTickerChange, onTradeNumberChange, onTradeStatusChange, trade} = props;
  const [isOpen,setIsOpen] = useState(false);
  const tradeStrate = useSelector((state:RootState) => state.trade )
  const [tradeType,setTradeType] = useState( localData.get("tradeType") || 'a' );
  const [tradeSource,setTradeSource] = useState(0);
  const [tradeStatus,setTradeStatus] = useState('o');

  const setType = (type:string) => {
    localData.set("tradeType", type);
    setTradeType(type);
    tradeTypeChange(type);
  }
  const setSource = (src:number) => {
    setTradeSource(src);
    tradeSourceChange(src);
  }
  const setStatus = (status:string) => {
    setTradeStatus(status);
    onTradeStatusChange && onTradeStatusChange(status);
  }

  useEffect(() => {
    if( !tradeStrate.isLoading ) setIsOpen(false);
  },[tradeStrate.isLoading]);

  return (
    <thead>
      <tr style={{verticalAlign: "middle" }}>
        <th>{!detailPage && <EditSearchField title="Trade#" onChanged={onTradeNumberChange}/>}</th>
        <th>Date</th>
        <th>{!detailPage && <EditSearchField title="Ticker" onChanged={onTickerChange}/>}</th>
        <th>{detailPage ? "Type" : <DropdownList onSelected={setType} data={tradeTypes} showAll={true} value={tradeType} cssID="dropDownListHeader"/>}</th>
        <th>Buy/Sell</th>
        <th>Quantity</th>
        <th>Price</th>
        <th>P&L (est)</th>
        <th>Spread</th>
        <th>Strikes</th>
        <th>Expires</th>
        <th>{detailPage ? "Status" : <DropdownList onSelected={setStatus} data={tradeStatuses} showAll={true} value={tradeStatus} cssID="dropDownListHeader"/>}</th>
        <th>{detailPage ? "Source" : <DropdownIDList cssID="dropDownListHeader" showAll={true} value={tradeSource} data={tradeSources} onSelected={setSource}/>}</th>
        <th style={{textAlign:"right"}}>
          <PlusCircle color="blue" onClick={()=>setIsOpen(!isOpen)} style={{marginTop:"4px",marginRight:"4px"}} />
        </th>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan={14}>
            <TradeAdd trade_source_id={trade?.trade_source_id} trade_number={trade?.trade_number} security_id={trade?.security_id}/>
          </td>
        </tr>
      )}
    </thead>
  )
}

  interface iRow {
    trade: typeTrade;
    detailPage?:boolean;
  }
  const TradeRow = (props:iRow) => {
    const {trade, detailPage } = props ;
    const [isOpen,setIsOpen] = useState(false);
    const tradeState = useSelector((state:RootState) => state.trade )
    const dispatch = useAppDispatch();  

    useEffect(() => {
      if( !tradeState.isLoading ) setIsOpen(false);
    },[tradeState.isLoading]);

    const tradeDelete = (tradeID:number) => {
      if( window.confirm("Are you sure you want to delte this trade?") ) {
        dispatch( deleteTrade(tradeID) );
      }
    }

    const tradeUpdate = async (tradeID:number, key:string, value:any) => {
      dispatch( updateTrade({tradeID: tradeID, updateData: {[key]: value} }) );
    }
  
    const tradeClose = (tradeID:number,tradeStatus:string) => {
      if( tradeStatus==='c' ) {
        dispatch( closeTrade(tradeID) );
      } else if( tradeStatus==='w' ) {
        dispatch( updateTrade({tradeID: tradeID, updateData: {closed_date: null, trade_status: 'w'} }) );
      } else {
        dispatch( updateTrade({tradeID: tradeID, updateData: {closed_date: null, trade_status: 'o'} }) );
      }
    }

    const AddTradeRow = (trade?:typeTrade) => {
      return (
        <tr>
          <td colSpan={14}>
            <TradeAdd trade_source_id={trade?.trade_source_id} trade_number={trade?.trade_number} security_id={trade?.security_id} small={true}/>
          </td>
        </tr>
      )
    }

  const trade_strikes = trade.trade_strikes || "";
    return (
      <>
      <tr key={trade.id} className={trade.trade_status==="o" && detailPage ? "rowOpen" : ""}>
        <td style={{whiteSpace: "nowrap"}}>
          {detailPage === true ? <>
            <Link to={`/trades/${trade.id}/edit`}> {trade.trade_number} </Link>
            </>
            : <Link to={`/trades/${trade.trade_number}/${trade.trade_source_id}`}>{trade.trade_number}</Link>}
        </td>
        <td>
          <EditDateField useOrigTime={true} date={new Date(trade.trade_date)} onChanged={(val)=>tradeUpdate(trade.id,'trade_date', val)} />
        </td>
        <td>{trade.ticker}</td>
        <td>{tradeTypes[trade.trade_type]}</td>
        <td>{trade.buy_sell}</td>
        <td><EditNumberField num={trade.quantity} format={EditNumberFieldFormats.whole} onChanged={(val)=>tradeUpdate(trade.id,'quantity', val)} /> </td>
        <td><EditNumberField num={trade.trade_price} format={EditNumberFieldFormats.decimal} onChanged={(val)=>tradeUpdate(trade.id,'trade_price', val)} /></td>
        <td><FormattedNumber number={trade.trade_cost.toString()} /> </td>
        <td>{trade.structure}</td>
        <td><EditTextField txt={trade_strikes} isLoading={isOpen} onChanged={(val)=>tradeUpdate(trade.id,'trade_strikes', val)}/></td>
        <td>
          {trade.expiration_date!==null ? <EditDateField date={new Date(trade.expiration_date)} onChanged={(val)=>tradeUpdate(trade.id,'expiration_date', val)} /> : null}
        </td>
        <td>
          <DropdownList data={tradeStatuses} value={trade.trade_status} onSelected={(val)=>{tradeClose(trade.id, val)}}/>
        </td>
        <td><DropdownIDList data={tradeSources} value={trade.trade_source_id} onSelected={(val)=>tradeUpdate(trade.id, 'trade_source_id', val)} /></td>
        <td>
          <div style={{display: "flex", justifyContent: "center" }}>
            <XCircle    color="red"  onClick={()=>tradeDelete(trade.id)} style={{margin:"4px"}} />
            &nbsp;
            {!detailPage && <PlusCircle style={{margin: '4px'}} color="blue" onClick={()=>setIsOpen(!isOpen)}/>}
          </div>
        </td>
      </tr>
      {isOpen && <AddTradeRow {...trade}/>}
      </>
    )
  }

  interface iProfitLossRow {
    trades: typeTrade[];
  }
  const ProfitLossRow = (props:iProfitLossRow) => {
    var types =  Array.from(new Set(props.trades.map(t => t.trade_type)) ) ;
    interface iHash {
      [key: string]: number;
    }
    var rows:iHash = {}

    props.trades.map((trade) => {
      rows[`${trade.trade_type}-cost`] = (rows[`${trade.trade_type}-cost`] || 0) + Number(trade.trade_cost);
      rows[`${trade.trade_type}-price`] = (rows[`${trade.trade_type}-price`] || 0) + Number(trade.trade_price);
      rows[`${trade.trade_type}-quantity`] = (rows[`${trade.trade_type}-quantity`] || 0) + Number(trade.quantity);
      rows["total"] = (rows["total"] || 0) + Number(trade.trade_cost);
      return true;
    })

    return (
      <>{

      types.map((type)=> {
      const price = (type==="s" ? rows["s-cost"] / rows["s-quantity"] : rows[`${type}-price`]).toString();
      return (
        <tr key={type}>
          <td colSpan={3}/>
          <td><b>{tradeTypes[type]}</b></td>
          <td/>
          <td> {rows[`${type}-quantity`].toString() } </td>
          <td> <FormattedNumber number={ price }/> </td>
          <td> <FormattedNumber number={ rows[`${type}-cost`].toString() }/> </td>
          <td colSpan={6}/>
        </tr>
      );
      })
      }
      <tr>
        <td colSpan={3}/>
        <td><b>Total</b></td>
        <td colSpan={3}/>
        <td><b><FormattedNumber number={ (rows["total"] || 0).toString() }/></b></td>
        <td colSpan={6}/>
      </tr>
      </>
    ); //Return
  }

export {TradeHeader, TradeRow, ProfitLossRow};