import { useEffect, useState } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import { RootState } from "../platform/redux/store";
import { iAuthState } from "../platform/redux/authSlice";
import { iTradesState, typeTrade, getOpenTrades, getTrades } from "../platform/redux/tradeSlice";
import { useAppDispatch } from "../hooks";
import { connect } from "react-redux";
import {ProfitLossRow, TradeHeader, TradeRow} from "../components/TradeTable";
import { useSearchParams } from "react-router-dom";
import { iAppErrorState } from "../platform/redux/appErrorSlice";
import localData from "../platform/localData";

interface iTrades {
  auth: iAuthState,
  trade: iTradesState,
  appError: iAppErrorState
}

const Trades = (props:iTrades) => {
  const {trade} = props ;
  const [searchParams] = useSearchParams();
  const qTradeType = searchParams.get('type') || localData.get("tradeType")  || "a" ;
  const [tradeType, setTradeType] =  useState( qTradeType ||  "a");
  const [tradeSource, setTradeSource] = useState(0 as number);
  const [isBusy, setIsBusy] = useState(false);
  const [ticker, setTicker] = useState("");
  const [tradeNumber, setTradeNumber] = useState("");
  const [tradeStatus, setTradeStatus] = useState("o");
  const [trades, setTrades] = useState([] as typeTrade[]);
  const dispatch = useAppDispatch();  

  useEffect(() => {
    if (trade.openLoaded === false && trade.isLoading === false && trade.isError === false) {
      setIsBusy(true);
      dispatch( getOpenTrades() );
    }
  //eslint-disable-next-line
  },[]);

  useEffect(() => {
    setTrades( sortedTrades() );
    setIsBusy(false)
  // eslint-disable-next-line
  },[trade.trades]);

  const sortedTrades = () : (typeTrade[]) => {
    if( trade.trades.length <= 0 ) return [];
    const sorted = [...trade.trades].sort( (a, b) => {
      let tn = b['trade_number'] - a['trade_number'] ;
      let dt = (new Date(b['trade_date']).getTime()) - (new Date(a['trade_date']).getTime()) ;
      return tn || dt;
    });
    return sorted;
  }

  const filteredTrades = () : (typeTrade[]) => {
    if( trades.length <= 0 ) return [];
    let tradeArr: typeTrade[] = [];
    trades.map((trade) => {
      if( tradeSource > 0 && tradeSource.toString() !== trade.trade_source_id.toString() ) return null;
      if( tradeType !== "a" && tradeType !== trade.trade_type ) return null;
      if( ticker !== "" && ticker !== trade.ticker ) return null;
      if( tradeNumber !== "" && trade.trade_number !== parseInt(tradeNumber) ) return null;
      if( tradeStatus !== trade.trade_status ) return null;
      if( ticker === "" && tradeNumber === "" && trade.trade_status !== tradeStatus ) return null;
      tradeArr.push(trade);
      return trade;
    });

    return tradeArr;
  }

  const statusChanged = (status:string) => {
    console.log("statusChanged: " + status + " closedLoaded? " + trade.closeLoaded);
    setTradeStatus(status);

    if(trade.closeLoaded || status !== 'c') return;
    setIsBusy(true);
    dispatch( getTrades(`limit=1000`) );
  }

  const tickerEnter = (tick:string) => {
    if(tick==="" && ticker==="") return;
    setIsBusy(true);
    dispatch( getTrades(`ticker=${tick}`) );
    setTicker(tick);
  }

  const tradeNumberEnter = (tradeNo:string) => {
    if(tradeNumber === "" && tradeNo === "") return;
    setIsBusy(true);
    dispatch( getTrades(`trade_number=${parseInt(tradeNo)}`) );
    setTradeNumber(tradeNo);
  }

  return (
    <>
    <Container>
      <Table striped bordered hover size="sm" responsive="true">
        <TradeHeader  tradeTypeChange={setTradeType} 
                      tradeSourceChange={setTradeSource} 
                      onTickerChange={(val:string)=>tickerEnter(val)} 
                      onTradeStatusChange={statusChanged}
                      onTradeNumberChange={(val:string)=>tradeNumberEnter(val)}/>
        {isBusy && <Spinner />}
        <tbody>
        {
          filteredTrades().map(data => {
            return (
              <TradeRow key={data.id} trade={data}/>
            )
          })
        }
         <ProfitLossRow trades={filteredTrades()} />
        </tbody>
      </Table>
    </Container>
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  auth: state.auth as iAuthState,
  trade: state.trade as iTradesState,
  appError: state.appError as iAppErrorState
});

const TradesContrainer = connect(mapStateToProps)(Trades);
export default TradesContrainer;